<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>
            <span class="d-block">Historial de historias Clinicas</span>
            <template v-slot:button>
                <router-link :to="{ name: 'historias.perfil.usuario', params: { idUsuario: usuario.id } }"
                    class="btn btn-success btn-sm shadow-sm mx-2">
                    Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
                </router-link>
            </template>
        </page-header-compact>
        <div class="container-xl px-4">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="card mt-2 card-collapsable">
                <a class="card-header" href="#collapseFilters" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseFilters">
                    <div><i class="fa fa-filter fa-fw"></i>Filtros</div>
                    <div class="card-collapsable-arrow">
                        <i class="fas fa-chevron-down"></i>
                    </div>
                </a>
                <div class="collapse hide" id="collapseFilters">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="form-group">
                                    <label class="form-label font-weight-bolder">Sede</label>
                                    <auto-complete-sede v-model="sede"></auto-complete-sede>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label font-weight-bolder">Fecha</label>
                                    <input type="date" class="form-control" v-model="pagination.params.fecha" />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label font-weight-bolder">Profesional</label>
                                    <v-select-user v-model="pagination.params.id_user"></v-select-user>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <div class="form-group">
                                    <label class="form-label font-weight-bolder">Tipo de Atencion</label>
                                    <select v-model="pagination.params.id_tipo_historia" class="form-control form-control-sm">
                                        <option :value="item.id" v-for="item in tipos_historias" :key="item.id">{{item.nombre}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-12">
                                <div class="pt-4 justify-content-between">
                                    <button class="btn btn-secondary mt-2 btn-sm mx-2" @click="cargarHistorias(id_usuario)">
                                        <i class="fa fa-filter fa-fw"></i> Buscar
                                    </button>
                                    <button class="btn btn-warning mt-2 btn-sm" @click="resetFilters()">
                                        <i class="fa fa-broom fa-fw"></i> Limpiar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="alert alert-warning" v-show="!pagination.data.length">Sin historial de atenciones...</div>
                    <data-grid :data="pagination" @changePage="changePage($event)"
                        @perPageChange="perPageChange($event)" @search="search($event)" :searchBox="false">
                        <template #grid>
                            <div class="card card-icon mb-3 shadow-none border-left-lg" v-for="(item, i) in pagination.data" :key="`d_${i}`"
                            :class="{'border-gray-300':item.finalizada==='S','border-warning':item.finalizada==='N'}">
                                <div class="row">
                                    <div class="col-lg-3 bg-light text-center py-3">
                                        <div>
                                            <h3 class="font-weight-bolder">
                                                <button class="btn btn-success btn-sm"
                                                    v-if="item.id_user === user.id &&( item.finalizada === 'S' || item.ingreso==='finalizado')" 
                                                    @click="openModalHC(item)" title="Abrir Historia">
                                                    <i class="fa fa-folder-open fa-fw"></i>
                                                </button>
                                                H.C {{ item.id }}
                                            </h3>
                                        </div>
                                        <span class="font-weight-bolder badge badge-secondary-soft badge-marketing">
                                            {{ item.tipo_historia.nombre }}
                                        </span>
                                        <span class="font-weight-bolder badge badge-white badge-marketing mt-2">
                                            {{ item.created_at | formatDateTime }}
                                        </span>
                                        <span class="font-weight-bolder badge">
                                            {{ item.user.name }}
                                        </span>
                                    </div>
                                    <div class="col">
                                        <div class="card-body py-3">                                           
                                            <div v-if="!item.impresion_diagnostica" class="alert alert-warning-soft">
                                                <i>Historia clinica sin Diagnóstico</i>
                                            </div>
                                            <div v-else>
                                                <strong>Impresión Diagnóstica :</strong>
                                                <ul>
                                                    <li v-for="(diag,index) in item.impresion_diagnostica.items" :key="index">
                                                        <strong>{{ diag.diagnostico.ID_DIAG }}</strong> {{ diag.diagnostico.DESC_DIAG }}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="btn-group shadow-none" role="group">
                                                <button @click="imprimir(item.id);"
                                                    class="btn btn-outline-primary  btn-xs" title="Imprimir Historia">
                                                    <i class="fa fa-print fa-fw"></i> H.C Completa
                                                </button>
                                                <button @click="resumen(item.id);"
                                                    class="btn btn-outline-success  btn-xs" title="Resumen">
                                                    <div v-if="item.tipo_historia.codigo!=='URG'"><i class="fa fa-print fa-fw "></i> Resumen</div>
                                                    <div v-else><i class="fa fa-print fa-fw "></i> Epicrisis</div>
                                                </button>
                                                <button v-if="item.formulas.length" @click="formulaModal(item.id);"
                                                    class="btn btn-outline-info  btn-xs" title="Fórmula médica">
                                                    <i class="fa fa-print fa-fw "></i> Fórmulas
                                                </button>
                                                <button v-if="item.ordenes.length"
                                                    @click="ordenModal(item.id, 'procedimiento');"
                                                    class="btn btn-outline-secondary  btn-xs" title="Orden médica">
                                                    <i class="fa fa-print fa-fw "></i> Orden Procedimiento
                                                </button>
                                                <button v-if="item.ordenes.length && item.tipo_historia.codigo === 'URG'"
                                                    @click="ordenModal(item.id, 'medicamento');"
                                                    class="btn btn-outline-secondary  btn-xs" title="Orden médica">
                                                    <i class="fa fa-print fa-fw "></i> Orden Medicamento
                                                </button>
                                                <button v-if="item.remisiones.length" type="button"
                                                    class="btn btn-outline-dark  btn-xs" title="Remisiones"
                                                    @click="abrirModal(item.id)">
                                                    <i class="fa fa-print fa-fw "></i> Remisiones
                                                </button>
                                                <button v-if="item.evoluciones.length" type="button"
                                                    class="btn btn-outline-warning   btn-xs" title="Evoluciones"
                                                    @click="EvolucionModal(item.id)">
                                                    <i class="fa fa-print fa-fw "></i> Evoluciones
                                                </button>
                                                <button v-if="item.incapacidades.length" type="button"
                                                    class="btn btn-outline-danger   btn-xs" title="Incapacidades"
                                                    @click="IncapacidadModal(item.id)">
                                                    <i class="fa fa-print fa-fw "></i> Incapacidades
                                                </button>
                                                <button  type="button"
                                                    class="btn btn-outline-danger   btn-xs" title="Notas de enfermeria"
                                                    @click="openModalNotasEnfermeria(item.id_usuario)">
                                                    <i class="fa fa-print fa-fw "></i> Notas Enfermería
                                                </button>
                                            </div>
                                            <div class="d-flex justify-content-end mt-2">
                                                <small class="font-weight-bolder" v-if="item.sede"><i class="fas fa-map-marker-alt fa-fw"></i> {{ item.sede.NOMBRE_SEDE }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </data-grid>
                    <modal-remisiones ref="modalRemisiones"></modal-remisiones>
                    <modal-ordenes ref="modalOrdenes"></modal-ordenes>
                    <modal-formulas ref="modalFormulas"></modal-formulas>
                    <modal-evoluciones ref="modalEvoluciones"></modal-evoluciones>
                    <modal-incapacidades ref="modalIncapacidades"></modal-incapacidades>
                    <modalOpenHC ref="modalHC"></modalOpenHC>
                    <modalNotasEnfermeria ref="modalNotasEnfermeria"></modalNotasEnfermeria>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import usuarioService from '../../../../services/usuarioService';
import historiaClinicaService from '../../../../services/historiaClinicaService';
import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
import Toast from '../../../../components/common/utilities/toast';
import modalRemisiones from "./modalRemisiones";
import modalOrdenes from "./modalOrdenes";
import modalFormulas from "./modalFormulas";
import modalEvoluciones from "./modalEvoluciones";
import modalIncapacidades from "./modalIncapacidades";
import modalOpenHC from "./abrirHistoria/modalOpenHC";
import AutoCompleteSede from "../../../../components/common/autocompletes/AutoCompleteSede"
import VSelectUser from "../../../../components/common/vSelectUser";
import tipoHistoriaService from "../../../../services/tipoHistoriaService";
import modalNotasEnfermeria from "./modalNotasEnfermeria";
export default {
    components: {
        modalInformacionUsuario, cardInformacionUsuario, PageHeaderCompact,
        DataGrid, modalRemisiones, modalOrdenes, modalFormulas, modalEvoluciones, modalIncapacidades,
        modalOpenHC,AutoCompleteSede,VSelectUser,modalNotasEnfermeria
    },
    data() {
        return {
            id_usuario: '',
            usuario: {},
            historias: [],
            pagination: {
                data: [],
                current_page: 1,
                page: 1,
                per_page: 5,
                search: '',
                total: '',
                params: {
                    id_user: "",
                    fecha: "",
                    id_sede: "",
                    id_tipo_historia: ""
                },
            },
            sede:{},
            tipos_historias: [],
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarHistorias(id) {
            try {
                this.LoaderSpinnerShow();
                const response = (await historiaClinicaService.showByIdPaciente(this.pagination.search, this.pagination, id))?.data;
                this.pagination.data = response.data;
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        imprimir(id) {
            window.open(historiaClinicaService.print(id));
            //this.$router.push({name : this.rutas_imprimir[tipo], params:{id:id}});
        },
        resumen(id) {
            window.open(historiaClinicaService.resumen(id));
        },
        changePage(page) {
            this.pagination.page = page;
            this.cargarHistorias(this.id_usuario);
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.cargarHistorias(this.id_usuario);
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.cargarHistorias(this.id_usuario);
        },
        abrirModal(id_historia) {
            this.$refs.modalRemisiones.abrir(id_historia);
        },
        formulaModal(id_historia) {
            this.$refs.modalFormulas.abrir(id_historia);
        },
        ordenModal(id_historia, type) {
            this.$refs.modalOrdenes.abrir(id_historia, type);
        },
        EvolucionModal(id_historia) {
            this.$refs.modalEvoluciones.abrir(id_historia);
        },
        IncapacidadModal(id_historia) {
            this.$refs.modalIncapacidades.abrir(id_historia);
        },
        openModalHC(historia) {
            this.$refs.modalHC.openModal(historia);
        },
        openModalNotasEnfermeria(idUsuario) {
            this.$refs.modalNotasEnfermeria.openModal(idUsuario);
        },
        resetFilters() {
            this.pagination.params.id_user = "";
            this.pagination.params.fecha = "";
            this.pagination.params.id_sede = "";
            this.pagination.params.id_tipo_historia = "";
            this.sede={};
            this.cargarHistorias(this.id_usuario);
        },
        async getTiposHistorias(){
            const response = await tipoHistoriaService.index();
            this.tipos_historias = response.data;
        }
    },
    async created() {
        this.id_usuario = this.$route.params.id;
        await this.cargarUsuario(this.$route.params.id);
        await this.cargarHistorias(this.$route.params.id);
        await this.getTiposHistorias();
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    watch: {
        sede : function (newVal, oldVal) {
            if(newVal.ID !== oldVal.ID){
                this.pagination.params.id_sede = newVal.ID;
            }
        }
    },
}
</script>