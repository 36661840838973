<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
import _service from "../../../services/sedeService";
import { isEmpty } from "lodash";

export default {
  props: ["value"],
  components: {
    Autocomplete,
  },
  created() {
    this.selected = this.value;
  },
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    search(input) {
      if (isEmpty(input)) return [];
      return new Promise((resolve, reject) => {
        _service
          .index(input)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getResultValue(result) {
      return result.NOMBRE_SEDE.toUpperCase();
    },
    onSubmit(result) {
      this.$emit("input", result);
    },
    clear() {
      this.value = "";
      this.$refs.autocomplete.value = "";
    },
  },
};
</script>

<template>
  <div class="justify-content-end">
        <autocomplete
          :search="search"
          :get-result-value="getResultValue"
          @submit="onSubmit"
          :debounceTime="300"
          placeholder="Seleccionar Sede"
          data-loading="false"
          ref="autocomplete"
          :default-value="selected.NOMBRE_SEDE"
        >
          <template #result="{ result, props }">
            <li v-bind="props">
              {{ result.CODIGO }} - {{ result.NOMBRE_SEDE }}
            </li>
          </template>
        </autocomplete>
        
      </div>
</template>
<style scoped></style>
